import React from 'react';
import Logo from '../../images/vorsorger_logo.png';
import Footer from '../AdminDashboard/Footer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import CookieConsent, { Cookies } from "react-cookie-consent";



const SignIn = (props) => {
      
      const [email, SetEmail] = React.useState("");
      const [password, SetPassword] = React.useState("");
      const dispatch = useDispatch();
      const history = useHistory();
      const username = "UserName";

      const handleEmailChange = (event) => {
        SetEmail(event.target.value);
      }

      const handlePasswordChange = (event) => {
        SetPassword(event.target.value);
      }

      const login = () => {
        const userData = {
          email: email,
          password: password
        };
        props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
      }

      useEffect(() => { 
        if (props.auth.isAuthenticated) {
          if(props.auth.user.isAdmin){
            props.history.push('/dashboard-admin');
          }else{
            props.history.push('/dashboard');
          }
        }
      });

    return (
      <div>
       <CookieConsent
        location="top"
        buttonText="Aktzeptieren"
        cookieName="cookie"
        style={{ background: "#ffff" }}
        buttonStyle={{ color: "#ffff", fontSize: "13px", background: "#425cbb" }}
        expires={150}
        contentStyle={{color: "#6f6f6f"}}
      >
        Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu gewährleisten.{"   "}
        <span style={{ fontSize: "10px" }}>Weitere Informationen in der <a href='https://www.dievorsorger.at/datenschutzerklaerung/'>Datenschutzerklärung</a></span>
       
      </CookieConsent>
        <div className="uk-section  uk-flex uk-flex-middle uk-animation-fade" data-uk-height-viewport>
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid>
                <div className="uk-width-1-1@m">
                  <div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
                    <img src={Logo} width={100} className="uk-align-center"/>
                    <h3 className="uk-card-title uk-text-center">Melde dich mit deinem Account an</h3>
                    <form>
                      <div className="uk-margin">
                        <div className="uk-inline uk-width-1-1">
                          <span className="uk-form-icon" uk-icon="icon: mail"></span>
                          <input className="uk-input uk-form-large" type="text" placeholder="Deine E-Mail Adresse" value={email} onChange={handleEmailChange}/>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <div className="uk-inline uk-width-1-1">
                          <span className="uk-form-icon" uk-icon="icon: lock"></span>
                          <input className="uk-input uk-form-large" type="password" placeholder="Dein Passwort" value={password} onChange={handlePasswordChange}/>	
                        </div>
                      </div>
                      <div className="uk-margin">
                        <a className="uk-button uk-button-primary uk-button-large uk-width-1-1" style={{background: "#425cbb"}} onClick={login}>Login</a>
                      </div>
                      <div className="uk-text-small uk-text-center">
                        Nicht registriert? <a href="/sign-up" style={{color: "#425cbb"}}>Account erstellen</a>
                      </div>
                      <div className="uk-text-small uk-text-center">
                        Passwort vergessen? <a href="/password-reset-email" style={{color: "#425cbb"}}>Passwort zurücksetzen</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }

  SignIn.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });

export default connect(mapStateToProps, {loginUser})(SignIn);