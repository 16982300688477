import React from 'react';
import VideoElement from './VideoElement';

const VideoCategoryElement = (props) => {

    return (
        <li>
            <a className="uk-accordion-title" href="#">{props.categroy}</a>
            <div className="uk-accordion-content">
                <div className="uk-margin">
                    <ul className="uk-list uk-list-divider">
                        {props.videos.map((value, index) => {
                            return <VideoElement key={index} videoName={value.videoName} category={props.categroy} videoIframe={value.videoIframe} />
                        })}
                    </ul>
                </div>
                <div className="uk-margin">

                </div>

            </div>
        </li>
    );
}

export default VideoCategoryElement;