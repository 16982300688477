import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
      marginTop: '10px',
    },

    paper: {
      padding: "10px",
      margin: "20px",
      textDecoration: 'none',
      "&:hover": {
        background: "#efefef",
      },

    },

    iconstyle: {
      color: "#4860b6"
    },
  })
);

const AdminDashboard = (props) => {

  const classes = useStyles();



  return (
    <div>
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <h1 className={classes.title} >
          Admin Dashboard
        </h1>
        <h2 className={classes.title}>

        </h2>
        <section>
          <div className="container">
            <div className="row text-center">

              <div className="col-md-4">
                <a href='/videos-admin' style={{ textDecoration: "none" }}>
                  <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                    <div><i className="fas fa-video fa-5x" style={{ color: "#425cbb" }} ></i></div>
                    <h4>Videos Verwalten</h4>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href='/customers-admin' style={{ textDecoration: "none" }}>
                  <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                    <div><i className="fas fa-address-book fa-5x" style={{ color: "#425cbb" }}></i></div>
                    <h4>Kunden Verwalten</h4>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href='/admin-data' style={{ textDecoration: "none" }}>
                  <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                    <div><i className="fas fa-user-cog fa-5x" style={{ color: "#425cbb" }}></i></div>
                    <h4>Meine Daten</h4>
                  </div>
                </a>

              </div>
            </div>

          </div>
        </section>

      </div>
      <Footer />
    </div>
  )
}

export default AdminDashboard;