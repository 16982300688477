import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import PropTypes from 'prop-types';
import Header from '../AdminDashboard/Header';
import Footer from '../AdminDashboard/Footer';
import { HUBSPOT_SCRIPT_LOADER } from '../../utils/constants';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
      marginTop: '10px',
    },

    paper: {
      padding: "10px",
      margin: "20px",
      textDecoration: 'none',
      "&:hover": {
        background: "#efefef",
      },

    },

    iconstyle: {
      color: "#4860b6"
    },
  })
);

const CustomerDashboard = (props) => {

  const classes = useStyles();
  const { user } = props.auth;
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    console.log('hey')

    // Add event listener.
    window.hsConversationsOnReady = [() => {
      setHasLoaded(true);
    }];

    // Create script component.
    let script = document.createElement('script');
    script.src = HUBSPOT_SCRIPT_LOADER;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    }
  }, []);

  return (
    <div>
      <Header />
      <div>
        <div>
          <h1 className={classes.title} >
            Willkommen bei DieVorsorger
          </h1>
          <h2 className={classes.title}>
            {user.firstname + " " + user.lastname}
          </h2>
          <section>
            <div className="container">
              <div className="row text-center">
                <div className="col-md-4">
                  <a href='/documents' style={{ textDecoration: "none" }}>
                    <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                      <div><i className="fas fa-file fa-5x" style={{ color: "#425cbb" }}></i></div>
                      <h4>Meine Dokumente</h4>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href='/calendar' style={{ textDecoration: "none" }}>
                    <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                      <div><i className="fas fa-calendar-alt fa-5x" style={{ color: "#425cbb" }}></i></div>
                      <h4>Beratungstermine</h4>
                    </div>
                  </a>
                </div>
                <div className="col-md-4">
                  <a href='/personal-data' style={{ textDecoration: "none" }}>
                    <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                      <div><i className="fas fa-user-cog fa-5x" style={{ color: "#425cbb" }}></i></div>
                      <h4>Meine Daten</h4>
                    </div>
                  </a>
                </div>
              </div>
              <div className="row text-center" >

                <div className="col-md-4" />

                <div className="col-md-4">
                  <a href='/videos' style={{ textDecoration: "none" }}>
                    <div className="uk-card uk-card-default uk-card-body uk-card-hover uk-margin">
                      <div><i className="fas fa-video fa-5x" style={{ color: "#425cbb" }} ></i></div>
                      <h4>Videos</h4>
                    </div>
                  </a>
                </div>

                <div className="col-md-4">
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

CustomerDashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(CustomerDashboard);