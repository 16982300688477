import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import UIkit from 'uikit';
import EditVideoModal from '../../Modals/EditVideoModal';
import VideoDisplayModal from '../../Modals/VideoDisplayModal';

const VideoElement = (props) => {

    const modalMediaYT =  "#" + `modal-media-youtube-${props.category}-${props.videoName}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g,"");
    const modalMediaYTId = `modal-media-youtube-${props.category}-${props.videoName}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g,"");

    const modalMediaEdit = "#" + `modal-media-edit-${props.category}-${props.videoName}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g,"");
    const modalMediaEditId = `modal-media-edit-${props.category}-${props.videoName}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g,"");


  
    const deleteVideo = () => {
      console.log(props.videoName);
      axios.delete(`${API_ENDPOINT}/api/videos/video`, { headers: {"auth-token" : props.token}, data: {category: props.category, videoName: props.videoName} });
    }

    const openVideo = () => {
      UIkit.modal(modalMediaYT).show();
    }

    const editVideo = () => {
      UIkit.modal(modalMediaEdit).show();
    }

    const subMitVideoChange = (newVideoName, newVideoIframe) => {

      axios({
        url: `${API_ENDPOINT}/api/videos/video`,
        method: 'put',
        headers: { 'auth-token': props.token },
        data: { oldVideoName: props.videoName, newVideoName: newVideoName, newVideoIframe: newVideoIframe, category: props.category }
      }).then((response) => {

        if(response.status === 200) {
          // All went right
          UIkit.notification({
              message: 'Video erfolgreich geändert',
              status: 'success',
              pos: 'top-right',
              timeout: 5000
          });
      }
      }).catch((error) => {
        console.log(error);
      
        UIkit.notification({
          message: 'Fehler beim ändern des Videos',
          status: 'warning',
          pos: 'top-right',
          timeout: 5000
      });
    });
    }
  
    return (
      <li>
        <EditVideoModal
          id={modalMediaEditId}
          text={"Video bearbeiten"}
          callback={(newVideoName, newVideoIframe) => subMitVideoChange(newVideoName, newVideoIframe)}
          videoName={props.videoName}
          videoIframe={props.videoIframe}
        />

        <VideoDisplayModal
          id={modalMediaYTId}
          videoIframe={props.videoIframe}
        />

        <span uk-icon="icon: play-circle" onClick={openVideo} style={{cursor: "pointer"}}></span> {props.videoName} 
        
        <div className="uk-align-right">

        <span uk-tooltip="title: Video bearbeiten" onClick={editVideo}  uk-icon="icon: pencil"  style={{cursor: "pointer"}}></span>
          <span uk-tooltip="title: Video löschen" uk-icon="icon: trash"  onClick={deleteVideo} style={{ cursor: "pointer"}}></span>
        </div>
          
      </li>
    )
  
  }

  export default VideoElement;