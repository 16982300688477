import React, { useEffect, useState } from 'react';
import Header from '../AdminDashboard/Header';
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import { API_ENDPOINT } from '../../utils/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VideoCategoryElement from './VideoCategoryElement';

const Videos = (props) => {
  const [videos, SetVideos] = useState([]);

  useEffect(() => {
    getVideos();
  });

  const getVideos = () => {
    axios.get(`${API_ENDPOINT}/api/videos`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetVideos(response.data);
      });
  }

  return (
    <div>
      <div style={{ height: "100vh" }}>
        <Header />
        <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
          Videos
        </h1>
        <div className="row" style={{ margin: "20px" }}>
          <div className="col-md-2" />
          <div className="col-md-8">

            <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>
              {videos.map((value, index) => {
                return <VideoCategoryElement key={index} categroy={value.category} videos={value.videos} />
              })}
            </ul>


          </div>
          <div className="col-md-2">

          </div>
        </div>

        <div className="row" style={{ margin: "20px" }} >
          <div className="uk-align-center">

          </div>
        </div>

      </div>
      <Footer />
    </div>
  )

}

Videos.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Videos);