import React from 'react';
import Logo from '../../images/vorsorger_logo.png';
import Footer from '../AdminDashboard/Footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';

const CheckEmail = (props) => {

    return (
        <div>
            <div className="uk-section  uk-flex uk-flex-middle uk-animation-fade" data-uk-height-viewport>
                <div className="uk-width-1-1">
                    <div className="uk-container">
                        <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid>
                            <div className="uk-width-1-1@m">
                                <div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
                                    <img src={Logo} width={100} className="uk-align-center" />
                                    <h3 className="uk-card-title uk-text-center">Überprüfe dein Email-Postfach</h3>
                                    <form>
                                        <div className="uk-text-small uk-text-center">
                                            Einloggen? <a href="/login" style={{ color: "#425cbb" }}>Login</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

CheckEmail.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(CheckEmail);