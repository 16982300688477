import React from 'react';
import UIkit from 'uikit';

const VideoElement = (props) => {

    const openVideo = () => {
        UIkit.modal('#modal-media-youtube').show();
    }

    return (
        <li>
            <div id="modal-media-youtube" class="uk-flex-top" className='uk-modal'>
                <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                    <div dangerouslySetInnerHTML={{ __html: props.videoIframe }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}></div>
                </div>
            </div>
            <span uk-icon="icon: play-circle" onClick={openVideo} style={{ cursor: "pointer" }}></span>  {props.videoName}
        </li>
    )

}

export default VideoElement;