import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { API_ENDPOINT } from '../../utils/constants';
import { GET_ERRORS, SET_CURRENT_TOKEN, SET_CURRENT_USER, USER_LOADING } from './types'; // Register User
import UIkit from 'uikit';

export const registerUser = (userData, history) => dispatch => {
  axios
    .post(`${API_ENDPOINT}/api/auth/register`, userData)
    .then(res => history.push('/check-email')) // re-direct to login on successful register
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });

      UIkit.notification({
        message: 'Fehler beim Registrieren: ' + err.response.data,
        status: 'warning',
        pos: 'top-right',
        timeout: 5000
      });
    }

    );
}; // Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post(`${API_ENDPOINT}/api/auth/login`, userData)
    .then(res => {
      // Save to localStorage// Set token to localStorage
      const { token } = res.data;
      localStorage.setItem('userData', res.data);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = res.data.user;//jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch(setCurrentToken(token));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS ? null : null,
        payload: err?.response?.data
      });

      UIkit.notification({
        message: 'Fehler beim Einloggen:  ' + err?.response?.data,
        status: 'warning',
        pos: 'top-right',
        timeout: 5000
      });

    }

    );
};

export const setCurrentToken = token => {
  console.log(token);

  return {
    type: SET_CURRENT_TOKEN,
    payload: token
  };
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
}; // User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
}; // Log user out
export const logoutUser = (history) => dispatch => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  history.push('/');
};