import React, { useState } from 'react';



const CreateVideoModal = (props) => {

    const [videoName, setVideoName] = useState(props.videoName);
    const [videoIframe, setVideoIframe] = useState(props.videoIframe);

    const handleVideoChange = (event) => {
        setVideoName(event.target.value);
    }

    const handleIframeChange = (event) => {
        setVideoIframe(event.target.value);
    }

    const handleSubmit = (event) => {
        props.callback(videoName, videoIframe);
        setVideoName('');
        setVideoIframe('');
    }

    // Text

    // Callback if confirmed

    // close modal

    return(
        <div id={props.id} className="uk-modal">
            <div class="uk-modal-dialog uk-modal-body">
                <input type="text" name="videoName" className="uk-input" placeholder="Video-Name" value={videoName} onChange={handleVideoChange} />
                <input style={{marginTop: "10px"}} type="text" name="videoIframe" className="uk-input" placeholder="Video-Iframe" value={videoIframe} onChange={handleIframeChange} />
                <button style={{marginTop: "10px"}} class="uk-button uk-button-default uk-modal-close " type="button">Abbrechen</button>
                <button style={{marginTop: "10px", marginLeft: "10px", background: "#425cbb"}} class="uk-button uk-button-primary uk-modal-close "  type="button" onClick={handleSubmit}>Bestätigen</button>
            </div>
        </div>
    )
}

export default CreateVideoModal;