import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from "../Header";
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import UIkit from 'uikit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loader from '../../../images/spinner.svg';
import ConfirmationModal from '../../Modals/Confirmation';
import FileUploadToContactModalAdmin from '../../Modals/FileUploadToContactModalAdmin';
import CustomerHubspotDocumentElement from './CustomerHubspotDocumentElement';
import CustomerDBDocument from './CustomerDBDocument';
import socketIOClient from "socket.io-client";

const uniqueId = Math.random().toString(16).slice(2);

const CustomerDetails = (props) => {

  const email = props.match.params.email;

  const [hubspotDocuments, SetHubspotDocuments] = useState(null);
  const [customerDBDocs, SetCustomerDBDocs] = useState(null);
  const [customerData, SetCustomerData] = useState(null);
  const [fileLoadingProgress, setFileLoadingProgress] = useState(0);

  const [hubspotDocsLoading, SetHubspotDocsLoading] = useState(true);
  const [customerDataLoading, SetCustomerDataLoading] = useState(true);
  const [customerDBDocsLoading, SetCustomerDBDocsLoading] = useState(true);

  const [hubspotDocsCalled, SetHubspotDocsCalled] = useState(false);

  useEffect(() => {
    if (hubspotDocsCalled === false) {
      getHubspotDocs();
    }
    if (customerData === null) {
      getCustomerData();
    }
    getCustomerDBDocs();
  });

  useEffect(() => {
    const socket = socketIOClient(API_ENDPOINT);

    socket.on("hubspotDocs" + uniqueId, data => {
      setFileLoadingProgress(data);
      console.log(data);
      var bar = document.getElementById('js-progressbar');
      if (bar) {
        bar.value = data;
      }
    });

    // CLEAN UP THE EFFECT
    return () => socket.disconnect();
  }, []);

  const getHubspotDocs = async () => {

    SetHubspotDocsCalled(true);

    await axios.get(`${API_ENDPOINT}/api/hubspotattachments/${email}/socketid/${uniqueId}`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetHubspotDocuments(response.data);
      }).catch((err) => {
        console.log(err);
      });
    SetHubspotDocsLoading(false);

  }

  const getCustomerDBDocs = async () => {
    console.log("called");
    await axios.get(`${API_ENDPOINT}/api/hubspotattachments/documents/${email}`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetCustomerDBDocs(response.data);
      }).catch((err) => {
        console.log(err.message);
      });
    SetCustomerDBDocsLoading(false);
  }

  const getCustomerData = async () => {
    await axios.get(`${API_ENDPOINT}/api/hubspotcontact/${email}`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetCustomerData(response.data);
      });
    SetCustomerDataLoading(false);
  }

  const deleteCustomer = async () => {
    await axios.delete(`${API_ENDPOINT}/api/users/delete`,
      { headers: { "auth-token": props.auth.token }, data: { email: email } })
      .then((response) => {
        props.history.push("/customers-admin");
      });
  }

  const openDelteUserModal = () => {
    UIkit.modal('#deleteusermodal').show();
  }

  const fileUploadModal = () => {
    UIkit.modal('#fileUploadToContactModal').show();
  }

  return (
    <div>
      <Header />
      <h3 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
        Kunde: {email}
      </h3>
      <div className="row" style={{ margin: "20px" }}>
        <div className="col-md-1" ></div>
        <div className="col-md-5">
          <h3 style={{ textAlign: "center" }} >Dokumente aus Hubspot</h3>

          {hubspotDocsLoading ? (<progress id="js-progressbar" class="uk-progress" value="0" max="100" style={{ display: "block", margin: "10" }} ></progress>) : (

            <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>

              {hubspotDocuments?.map((value, index) => {
                return <CustomerHubspotDocumentElement key={index} ProductName={value.name} email={email} productDocument={value.url} fileId={value.fileId} token={props.auth.token} />
              })}

            </ul>)}

          <button class="uk-button uk-button-primary uk-modal-close " style={{ marginLeft: "10px", background: "#425cbb" }} onClick={fileUploadModal}>
            Dokumente hochladen
          </button>

          <FileUploadToContactModalAdmin
            id={'fileUploadToContactModal'}
            auth={props.auth}
            email={email}
          />

          <h3 style={{ textAlign: "center" }} >Dokumente für den Nutzer sichtbar</h3>

          {customerDBDocsLoading ? (<img alt="" src={loader} style={{ display: "block", margin: "0 auto" }} />) : (

            <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>

              {customerDBDocs?.map((value, index) => {
                return <CustomerDBDocument email={email} ProductName={value.documentName} fileId={value.documentHubspotFileId} email={email} key={index} token={props.auth.token} />
              })}

            </ul>)}



        </div>
        <div className="col-md-5">
          <h3 style={{ textAlign: "center" }}>Kundendaten</h3>

          {customerDataLoading ? (<img alt="" src={loader} style={{ display: "block", margin: "0 auto" }} />) : (<div><ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>
            <li> Vorname:  {customerData?.firstname} </li>
            <li> Nachname:  {customerData?.lastname} </li>
            <li> Beruf:  {customerData?.jobtitle} </li>
            <li> E-Mail:  {customerData?.email} </li>
            <li> Telefon:  {customerData?.phone} </li>
            <li> Adresse:  {customerData?.address} </li>
            <li> Stadt:  {customerData?.city} </li>
            <li> PLZ:  {customerData?.zip} </li>
          </ul></div>)}

          <button class="uk-button uk-button-danger" style={{ margin: "10px", height: "50px" }} type="button" onClick={openDelteUserModal} >Benutzer Löschen</button>
          <ConfirmationModal
            id={'deleteusermodal'}
            callback={deleteCustomer}
            text={'Diesen Benutzer Löschen'}
          />
        </div>

        <div className="col-md-1" ></div>
      </div>
    </div >
  )
}


CustomerDetails.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CustomerDetails);