import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../utils/constants';
import UIkit from 'uikit';
import { deleteUmlaute } from '../../utils/StringExtensions';



const FileUploadToContactModal = (props) => {

    const [selectedFile, SetSelectedFile] = useState(null);

    const onFileUpload = () => {
        if (!selectedFile) {
            UIkit.notification({
                message: 'Fehler beim hochladen der Datei: Keine Datei angegeben ',
                status: 'warning',
                pos: 'top-right',
                timeout: 5000
            });
            return;
        }

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            selectedFile,
            deleteUmlaute(selectedFile.name)
        );

        // Details of the uploaded file
        //console.log(selectedFile);

        // Request made to the backend api
        // Send formData object
        const { user } = props.auth;
        const email = props.email;
        axios.post(`${API_ENDPOINT}/api/hubspotattachments/documents/upload/${email}`, formData, { headers: { "auth-token": props.auth.token } })
            .then((response) => {

                if (response.status === 200) {
                    // All went right
                    UIkit.notification({
                        message: selectedFile.name + ' <br/> Erfolgreich an DieVorsorger gesendet',
                        status: 'success',
                        pos: 'top-right',
                        timeout: 5000
                    });
                }
            }).catch((err) => {
                UIkit.notification({
                    message: 'Fehler beim hochladen der Datei: ' + err,
                    status: 'warning',
                    pos: 'top-right',
                    timeout: 5000
                });
                console.log(err);
            });;;
    }

    const onFileChange = (event) => {
        const fileToUpload = event.target.files[0];
        SetSelectedFile(fileToUpload);
    }

    return (
        <div>
            <div id={props.id} className="uk-modal">
                <div class="uk-modal-dialog uk-modal-body">
                    <h3>
                        Dokumente versenden
                    </h3>
                    <div>
                        <div class="row">
                            <div class="col-6">
                                <input type="file" onChange={onFileChange} />
                            </div>
                            <div class="col-6">
                                <button class="uk-button uk-button-primary uk-modal-close " style={{ marginLeft: "10px", background: "#425cbb" }} onClick={onFileUpload}>
                                    Jetzt abschicken
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default FileUploadToContactModal;