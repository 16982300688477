import React from 'react';
import styled from 'styled-components';

const Footer = (props) => {

    return (
        <FooterElement>
            <div className="container">
                <span className="text-muted">© 2022 DieVorsorger Vorsorge- und Vermögensberatung GmbH</span>

                <span className="text-muted" style={{ float: "right", marginLeft: "10px" }}><a href="https://www.dievorsorger.at/datenschutzerklaerung/">Datenschutz</a></span>
                <span className="text-muted" style={{ float: "right" }}><a href="https://www.dievorsorger.at/impressum/">Impressum</a></span>

            </div>
        </FooterElement>
    );
}

const FooterElement = styled.footer`
    position: relative;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    border-top: 1px solid #f5f5f5;
`;

export default Footer;