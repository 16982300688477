import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from "../Header";
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerListElement from './CustomerListElement';

const Customers = (props) => {

  const [customers, SetCustomers] = useState([]);

  useEffect(() => {
    getCustomers();
  });

  const getCustomers = () => {

    axios.get(`${API_ENDPOINT}/api/users/customers`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetCustomers(response.data);
      });
  }


  return (
    <div>
      <Header />
      <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
        Kundenliste
      </h1>
      <div className="row" style={{ margin: "20px" }}>
        <div className="col-md-1" ></div>
        <div className="col-md-10">

          <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>
            {customers.map((value, index) => {
              return <CustomerListElement key={index} CustomerName={value.firstname + " " + value.lastname} email={value.email} token={props.auth.token} />
            })}
          </ul>

        </div>

        <div className="col-md-1" ></div>
      </div>
    </div>
  )
}


Customers.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Customers);

