import React, { useState, useEffect } from 'react';
import Header from '../AdminDashboard/Header';
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import { API_ENDPOINT } from '../../utils/constants';
import loader from '../../images/spinner.svg';
import UIkit from 'uikit';
import FileUploadToContactModal from '../Modals/FileUploadToContactModal';

const DocumentListElement = (props) => {

  const download = () => {

    const fileId = props.fileId;
    axios({
      url: `${API_ENDPOINT}/api/hubspotattachments/documents/fileurl/${fileId}/${props.email}`,
      method: "GET",
      headers: { 'auth-token': props.token }
    }).then((response) => {
      window.open(response.data);
    }).catch((err) => {
      UIkit.notification({
        message: 'Dokument konnte nicht gefunden werden!',
        status: 'danger',
        pos: 'top-right',
        timeout: 5000
      });
    });

  }

  return (
    <li style={{ paddingBottom: "10px" }}>
      <a className="uk-accordion-title" href="#">{props.InsuranceName}</a>
      <div className="uk-accordion-content">
        <p className="uk-margin">
          <button onClick={download} className="uk-margin uk-button">Download</button>
        </p>
      </div>
    </li>
  )
}

const Documents = (props) => {

  const [documents, SetDoucments] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);

  const { user } = props.auth;
  const email = user.email;

  useEffect(() => {
    getDocuments();
  });

  const getDocuments = async () => {

    await axios.get(`${API_ENDPOINT}/api/hubspotattachments/documents/${email}`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetDoucments(response.data);
      });
    SetIsLoading(false);
  }

  const fileUploadModal = () => {
    UIkit.modal('#fileUploadToContactModal').show();
  }

  return (
    <div>
      <div class="" style={{ minHeight: "100vh" }}>
        <Header />
        <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
          Meine Dokumente
        </h1>
        <div className="row " style={{ margin: "20px" }}>
          <div className="col-md-2" />
          <div className="col-md-8">
            {
              isLoading ? (<img src={loader} style={{ display: "block", margin: "0 auto" }} />) : (<div> <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>

                {documents?.map((value, index) => {

                  return <DocumentListElement email={email} token={props.auth.token} key={index} InsuranceName={value.documentName} fileId={value.documentHubspotFileId} />
                })}

              </ul></div>)
            }

            <button class="uk-button uk-button-primary uk-modal-close " style={{ margin: "0 auto", display: "block", background: "#425cbb", marginBottom: "10px" }} onClick={fileUploadModal}>
              Dokument hochladen
            </button>



            <FileUploadToContactModal
              id={'fileUploadToContactModal'}
              auth={props.auth}
              email={email}
            />

          </div>
          <div className="col-md-2" />
        </div>

      </div>
      <Footer />
    </div>
  )
}

Documents.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Documents);



