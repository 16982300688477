import './App.css';
import SignIn from './components/CustomerDashboard/SignIn';
import SignUp from './components/CustomerDashboard/SignUp';
import DashBoard from './components/CustomerDashboard/Dashboard';
import Documents from './components/CustomerDashboard/Documents';
import 'bootstrap/dist/css/bootstrap.css';
import 'uikit/dist/css/uikit.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Calendar from './components/CustomerDashboard/Calendar';
import { Helmet } from 'react-helmet';
import PersonalData from './components/CustomerDashboard/PersonalData';
import Videos from './components/CustomerDashboard/Videos';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import AdminDashboard from './components/AdminDashboard/Dashboard';
import VideoDashboard from './components/AdminDashboard/Videos/Videos';
import Customers from './components/AdminDashboard/CustomerManagement/Customers';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store';
import CustomerDetails from './components/AdminDashboard/CustomerManagement/CustomerDetails';
import AdminData from './components/AdminDashboard/AdminData';
import SendPasswordResetEmail from './components/CustomerDashboard/SendPasswordResetEmail';
import PasswordReset from './components/CustomerDashboard/PasswordReset';
import CheckEmail from './components/CustomerDashboard/CheckEmail';
import AccountActivated from './components/CustomerDashboard/AccountActivated';
import { HUBSPOT_SCRIPT_LOADER } from './utils/constants';
const { store, persistor } = configureStore();

function App() {
  // Check for token to keep user logged in
  if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded)); // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser()); // Redirect to login
      window.location.href = './login';
    }
  }


  UIkit.use(Icons);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <BrowserRouter>
          <Switch >
            <Route exact path='/' component={SignIn} />
            <Route exact path='/login/' component={SignIn} />
            <Route exact path='/sign-up/' component={SignUp} />
            <Route exact path='/password-reset-email/' component={SendPasswordResetEmail} />
            <Route exact path='/password-reset/:userId/:token' render={(props) => <PasswordReset {...props} />} />
            <Route exact path='/account-activated/:uniqueString' render={(props) => <AccountActivated {...props} />} />
            <Route exact path='/check-email/' component={CheckEmail} />
            <Route exact path='/dashboard/' component={DashBoard} />
            <Route exact path='/documents/' component={Documents} />
            <Route exact path='/calendar/' component={Calendar} />
            <Route exact path='/personal-data/' component={PersonalData} />
            <Route exact path='/videos/' component={Videos} />

            <Route exact path='/dashboard-admin/' component={AdminDashboard} />
            <Route exact path='/videos-admin/' component={VideoDashboard} />
            <Route exact path='/customers-admin/' component={Customers} />
            <Route exact path='/customer-details/:email' render={(props) => <CustomerDetails  {...props} />} />
            <Route exact path='/admin-data/' component={AdminData} />
          </Switch>

          <Helmet>
            <script type="text/javascript" style={{ maxHeight: "300px" }} src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
            <script type="text/javascript" id="hs-script-loader" async defer src={HUBSPOT_SCRIPT_LOADER}></script>

          </Helmet>

        </BrowserRouter>
      </PersistGate>
    </Provider>





  );
}

export default App;
