import React, { useState } from 'react';
import Logo from '../../images/vorsorger_logo.png';
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import UIkit from 'uikit';
import { API_ENDPOINT } from '../../utils/constants';



const PasswordReset = (props) => {

    const userId = props.match.params.userId;
    const token = props.match.params.token;

    const [newPassword1, setnewPassword1] = useState("");
    const [newPassword2, setnewPassword2] = useState("");


    const handleNewPassword1Change = (event) => {
        setnewPassword1(event.target.value);
    }

    const handleNewPassword2Change = (event) => {
        setnewPassword2(event.target.value);
    }

    const resetPassword = () => {
        console.log(userId);
        console.log(token);
        
        axios({
            url: `${API_ENDPOINT}/api/auth/pwreset/${userId}/${token}`,
            method: 'post',
            //headers: { 'auth-token': props.auth.token },
            data: {
                newPassword1: newPassword1, 
                newPassword2: newPassword2
            }
        }).then((response) => {

            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Passwort erfolgreich geändert',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
                props.history.push('/login');
            }
        }).catch((err) => {
            UIkit.notification({
                message: 'Fehler beim ändern des Passworts',
                status: 'warning',
                pos: 'top-right',
                timeout: 5000
            });
            console.log(err);
        });;
    }

    return (
        <div>
            <div className="uk-section  uk-flex uk-flex-middle uk-animation-fade" data-uk-height-viewport>
                <div className="uk-width-1-1">
                    <div className="uk-container">
                        <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid>
                            <div className="uk-width-1-1@m">
                                <div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
                                    <img src={Logo} width={100} className="uk-align-center" />
                                    <h3 className="uk-card-title uk-text-center">Passwort Zurücksetzen</h3>
                                    <form>
                                        <div className="uk-margin">
                                            <div className="uk-inline uk-width-1-1">
                                                <span className="uk-form-icon" uk-icon="icon: lock"></span>
                                                <input className="uk-input uk-form-large" type="password" placeholder="Neues Passwort" value={newPassword1} onChange={handleNewPassword1Change} />
                                            </div>
                                        </div>
                                        <div className="uk-margin">
                                            <div className="uk-inline uk-width-1-1">
                                                <span className="uk-form-icon" uk-icon="icon: lock"></span>
                                                <input className="uk-input uk-form-large" type="password" placeholder="Neues Passwort Wiederhohlen" value={newPassword2} onChange={handleNewPassword2Change} />
                                            </div>
                                        </div>
                                        <div className="uk-margin">
                                            <a className="uk-button uk-button-primary uk-button-large uk-width-1-1" style={{background: "#425cbb"}} onClick={resetPassword}>Passwort Ändern</a>
                                        </div>
                                        <div className="uk-text-small uk-text-center">
                                            Bereits registriert? <a href="/login" style={{color: "#425cbb"}}>Login</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

PasswordReset.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(PasswordReset);