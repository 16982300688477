import React, { useEffect, useState } from 'react';
import Header from '../AdminDashboard/Header';
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import { API_ENDPOINT } from '../../utils/constants';
import UIkit from 'uikit';
import ConfirmationModal from '../Modals/Confirmation';

const AdminData = (props) => {

    const [userId, setUserId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setUserEmail] = useState("");


    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword1, setnewPassword1] = useState("");
    const [newPassword2, setnewPassword2] = useState("");



    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    }

    const handleNewPassword1Change = (event) => {
        setnewPassword1(event.target.value);
    }

    const handleNewPassword2Change = (event) => {
        setnewPassword2(event.target.value);
    }


    useEffect(() => {
        loadAdminData();
    }, []);

    const loadAdminData = () => {
        if (userId !== "") {
            return;
        }

        const { user } = props.auth;
        setUserId(user.id);
        setFirstName(user.firstname);
        setLastName(user.lastname);
        setUserEmail(user.email);
    }

    const passwordChangeModal = () => {
        UIkit.modal("#savePasswordChangeModal").show();
    }

    const savePasswordChange = async () => {

        const { user } = props.auth;

        axios({
            url: `${API_ENDPOINT}/api/users/password/${user.id}`,
            method: 'put',
            headers: { 'auth-token': props.auth.token },
            data: {
                id: user.id,
                currentPassword: currentPassword,
                newPassword1: newPassword1,
                newPassword2: newPassword2
            }
        }).then((response) => {
            //console.log(response);
            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Passwort erfolgreich geändert',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
            }
        }).catch((err) => {
            UIkit.notification({
                message: 'Fehler beim ändern des Passworts',
                status: 'warning',
                pos: 'top-right',
                timeout: 5000
            });
        });
    }

    return (
        <div>
            <Header />
            <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
                Meine Daten
            </h1>
            <div><div className="row " style={{ margin: "20px" }}>
                <div className="col-md-2" />
                <div className="col-md-8" >

                    <div>
                        <div className="uk-fieldset">

                            <div className="uk-card uk-card-default uk-card-body uk-card-hover">
                                <div className="uk-margin">
                                    <h1>Kontaktdaten</h1>
                                </div>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="uk-margin">
                                            <label for="">Vorname</label>
                                            <input className="uk-input" type="text" placeholder="Vorname" value={firstName} readOnly style={{ color: 'lightgrey' }} /*onChange={handleFirstNameChange}*/ />
                                        </div>
                                        <div className="uk-margin">
                                            <label for="">E-Mail</label>
                                            <input className="uk-input" type="text" placeholder="Email" value={email} readOnly style={{ color: 'lightgrey' }}  /*onChange={handleEmailChange}*/ />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="uk-margin">
                                            <label for="">Nachname</label>
                                            <input className="uk-input" type="text" placeholder="Nachname" value={lastName} readOnly style={{ color: 'lightgrey' }}/*onChange={handleLastNameChange}*/ />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="uk-card uk-card-default uk-margin uk-card-body uk-card-hover">
                                <div className="uk-margin">
                                    <h1>Passwort ändern</h1>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="uk-margin">
                                            <label >Aktuelles Passwort</label>
                                            <input className="uk-input" type="password" placeholder="Aktuelles Passwort" value={currentPassword} onChange={handleCurrentPasswordChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label >Neues Passwort</label>
                                            <input className="uk-input" type="password" placeholder="Neues Passwort" value={newPassword1} onChange={handleNewPassword1Change} />
                                        </div>

                                        <div className="uk-margin">
                                            <label>Neues Passwort wiederholen</label>
                                            <input className="uk-input" type="password" placeholder="Neues Passwort bestätigen" value={newPassword2} onChange={handleNewPassword2Change} />
                                        </div>
                                    </div>

                                </div>

                                <ConfirmationModal
                                    id={'savePasswordChangeModal'}
                                    callback={savePasswordChange}
                                    text={'Änderungen des Passworts speichern'}
                                />

                                <div className="uk-margin">
                                    <button className="uk-button uk-button-primary uk-button-large uk-width-1-1" style={{ background: "#425cbb" }} onClick={passwordChangeModal}>Änderungen Speichern</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2" />
            </div></div>

            <Footer />
        </div>

    )

}

AdminData.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(AdminData);