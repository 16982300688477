import React from 'react';
import Logo from '../../images/vorsorger_logo.png';
import Footer from '../AdminDashboard/Footer';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import { API_ENDPOINT } from '../../utils/constants';
import loader from '../../images/spinner.svg';

const AccountActivated = (props) => {
    const uniqueString = props.match.params.uniqueString;
    const [hasLoaded, setHasLoaded] = useState(false);
    const [successFullActivation, SetSuccessFullActivation] = useState(false);

    useEffect(() => {
        if(hasLoaded === false){
            activateAccount();
        }
    });

    const activateAccount = () => {
        axios({
            url: `${API_ENDPOINT}/api/auth/verify/${uniqueString}`,
            method: 'get'
        }).then((response) => {

            if (response.status === 200) {
                // All went right
                setHasLoaded(true);
                SetSuccessFullActivation(true);
            }
        }).catch((err) => {
            setHasLoaded(true);
            SetSuccessFullActivation(false);
            console.log(err);
        });;
    }

    return (
        <div>
            <div className="uk-section  uk-flex uk-flex-middle uk-animation-fade" data-uk-height-viewport>
                <div className="uk-width-1-1">
                    <div className="uk-container">
                        <div className="uk-grid-margin uk-grid uk-grid-stack" data-uk-grid>
                            <div className="uk-width-1-1@m">
                                <div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
                                    <img src={Logo} width={100} className="uk-align-center" />
                                    {
                                        hasLoaded === false ? (<img src={loader} style={{ display: "block", margin: "0 auto" }} />) : 
                                        (<div></div>)
                                    }
                                    {
                                        (successFullActivation && hasLoaded) ? (<h3 className="uk-card-title uk-text-center">Du wurdest erfolgreich Registriert</h3>) : (<div></div>)
                                    }
                                    {
                                        (successFullActivation === false && hasLoaded) ? (<h3 className="uk-card-title uk-text-center">Registrieren Fehlgeschlagen</h3>) : (<div></div>)
                                    }
                                    <form>
                                        <div className="uk-text-small uk-text-center">
                                            Account aktiviert? <a href="/login" style={{color: "#425cbb"}}>Login</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

AccountActivated.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(AccountActivated);