import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import UIkit from 'uikit';

const CustomerDBDocument = (props) => {

    const openProduct = async () => {

        const fileId = props.fileId;

        await axios.get(`${API_ENDPOINT}/api/hubspotattachments/documents/fileurl/${fileId}/${props.email}`,
            { headers: { "auth-token": props.token } })
            .then((response) => {
                window.open(response.data);
            }).catch((err) => {
                UIkit.notification({
                    message: 'Dokument konnte nicht gefunden werden!',
                    status: 'danger',
                    pos: 'top-right',
                    timeout: 5000
                });
            });
    }

    const deleteUserDocument = () => {
        axios({
            url: `${API_ENDPOINT}/api/hubspotattachments/documents`,
            method: 'delete',
            headers: { 'auth-token': props.token },
            data: { email: props.email, fileName: props.ProductName, fileId: props.fileId }
        }).then((response) => {

            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Dokument erfolgreich gelöscht',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
            }
        }).catch((error) => {
            UIkit.notification({
                message: 'Dokument konnte nicht gelöscht werden',
                status: 'danger',
                pos: 'top-right',
                timeout: 5000
            });
        });
    }

    return (
        <li style={{ cursor: "pointer" }}>
            <span uk-icon="icon: file-pdf" onClick={openProduct} ></span>  {props.ProductName}
            <span className='float-right' style={{ color: "red" }} uk-icon="icon: trash" onClick={deleteUserDocument}></span></li>
    )

}

export default CustomerDBDocument;