import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import UIkit from 'uikit';


const CustomerHubspotDocumentElement = (props) => {

    const openProduct = () => {
        const fileId = props.fileId;
        axios({
            url: `${API_ENDPOINT}/api/hubspotattachments/documents/fileurl/${fileId}/${props.email}`,
            method: 'get',
            headers: { 'auth-token': props.token },
        }).then((response) => {
            console.log(response)
            window.open(response.data);
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const addDocumentToCustomer = () => {
        axios({
            url: `${API_ENDPOINT}/api/hubspotattachments/documents`,
            method: 'post',
            headers: { 'auth-token': props.token },
            data: { email: props.email, fileName: props.ProductName, fileId: props.fileId }
        }).then((response) => {

            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Dokument erfolgreich hinzugefügt!',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
            }
        }).catch((error) => {
            //console.log({ email: props.email, fileName: props.ProductName, fileId: props.fileId });
            UIkit.notification({
                message: 'Dokument konnte nicht hinzugefügt werden!',
                status: 'danger',
                pos: 'top-right',
                timeout: 5000
            });
        });
    }

    return (
        <li ><span uk-icon="icon: file-pdf" onClick={openProduct} style={{ cursor: "pointer" }}></span>   {props.ProductName}
            <span className='float-right' style={{ cursor: "pointer", textAlign: "end" }} uk-icon="icon: plus-circle" onClick={addDocumentToCustomer} ></span></li>
    )

}

export default CustomerHubspotDocumentElement;