import React, { useEffect, useState } from 'react';
import Header from '../AdminDashboard/Header';
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import { API_ENDPOINT } from '../../utils/constants';
import loader from '../../images/spinner.svg';
import UIkit from 'uikit';
import ConfirmationModal from '../Modals/Confirmation';


const PersonalData = (props) => {

    const [hubspotId, setHubspotId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setUserEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [address, SetAddress] = useState("");
    const [city, SetCity] = useState("");
    const [plz, setPlz] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword1, setnewPassword1] = useState("");
    const [newPassword2, setnewPassword2] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleJobTitleChange = (event) => {
        setJobTitle(event.target.value);
    }

    const handleAddressChange = (event) => {
        SetAddress(event.target.value);
    }

    const handleCityChange = (event) => {
        SetCity(event.target.value);
    }

    const handlePlzChange = (event) => {
        setPlz(event.target.value);
    }

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    }

    const handleNewPassword1Change = (event) => {
        setnewPassword1(event.target.value);
    }

    const handleNewPassword2Change = (event) => {
        setnewPassword2(event.target.value);
    }


    useEffect(() => {
        loadPersonalData();
    });

    const loadPersonalData = async () => {
        if (hubspotId !== "") {
            return;
        }

        const { user } = props.auth;
        const email = user.email;
        console.log(props.auth);
        await axios.get(`${API_ENDPOINT}/api/hubspotcontact/${email}`, { headers: { "auth-token": props.auth.token } })
            .then((response) => {
                setHubspotId(response.data.vid);
                setFirstName(response.data.firstname);
                setLastName(response.data.lastname);
                setUserEmail(response.data.email);
                setPhone(response.data.phone);
                setJobTitle(response.data.jobtitle);
                SetAddress(response.data.address);
                SetCity(response.data.city);
                setPlz(response.data.zip);
            });
        setIsLoading(false);
    }

    const savePersonalDataChange = async () => {

        axios({
            url: `${API_ENDPOINT}/api/hubspotcontact/${hubspotId}`,
            method: 'patch',
            headers: { 'auth-token': props.auth.token },
            data: {
                firstname: firstName,
                lastname: lastName,
                email: email,
                phone: phone,
                jobtitle: jobTitle,
                address: address,
                city: city,
                plz: plz
            }
        }).then((response) => {

            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Daten erfolgreich geändert',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
            }
        }).catch((err) => {
            console.log(err.message);
            UIkit.notification({
                message: 'Fehler beim ändern der Daten',
                status: 'warning',
                pos: 'top-right',
                timeout: 5000
            });
        });;
    }

    const savePasswordChange = async () => {

        const { user } = props.auth;


        axios({
            url: `${API_ENDPOINT}/api/users/password/${user.id}`,
            method: 'put',
            headers: { 'auth-token': props.auth.token },
            data: {
                id: user.id,
                currentPassword: currentPassword,
                newPassword1: newPassword1,
                newPassword2: newPassword2
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                // All went right
                UIkit.notification({
                    message: 'Passwort erfolgreich geändert',
                    status: 'success',
                    pos: 'top-right',
                    timeout: 5000
                });
            }
        }).catch((err) => {
            UIkit.notification({
                message: 'Fehler beim ändern des Passworts',
                status: 'warning',
                pos: 'top-right',
                timeout: 5000
            });
        });


    }

    const personalDataChangeModal = () => {
        UIkit.modal('#contactDataChangeModal').show();
    }

    const passwordChangeModal = () => {
        UIkit.modal('#changePasswordModal').show();
    }

    return (
        <div>
            <Header />
            <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
                Meine Daten
            </h1>
            {isLoading ? (<img src={loader} style={{ display: "block", margin: "0 auto" }} />) : (<div><div className="row " style={{ margin: "20px" }}>
                <div className="col-md-2" />
                <div className="col-md-8" >

                    <form>
                        <fieldset className="uk-fieldset">


                            <div className="uk-card uk-card-default uk-card-body uk-card-hover">
                                <div className="uk-margin">
                                    <h1>Kontaktdaten</h1>
                                </div>

                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="uk-margin">
                                            <label for="">Vorname</label>
                                            <input className="uk-input" type="text" placeholder="Vorname" value={firstName} text onChange={handleFirstNameChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label for="">E-Mail</label>
                                            <input className="uk-input" type="text" placeholder="E-Mail" value={email} readOnly /*onChange={handleEmailChange}*/ style={{ color: 'lightgray' }} />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="uk-margin">
                                            <label for="">Nachname</label>
                                            <input className="uk-input" type="text" placeholder="Nachname" value={lastName} onChange={handleLastNameChange} />
                                        </div>

                                        <div className="uk-margin">
                                            <label for="">Telefon</label>
                                            <input className="uk-input" type="text" placeholder="Telefon" value={phone} onChange={handlePhoneChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="uk-margin">
                                            <label for="">Berufsbezeichnung</label>
                                            <input className="uk-input" type="text" placeholder="Beruf" value={jobTitle} onChange={handleJobTitleChange} />
                                        </div>
                                    </div>

                                </div>

                                <div className="uk-margin">
                                    <h1>Adresse</h1>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="uk-margin">
                                            <label for="">Straße und Hausnummer</label>
                                            <input className="uk-input" type="text" placeholder="Straße und Hausnummer" value={address} onChange={handleAddressChange} />
                                        </div>

                                        <div className="uk-margin">
                                            <label for="">Ort</label>
                                            <input className="uk-input" type="text" placeholder="Ort" value={city} onChange={handleCityChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="uk-margin">
                                            <label for="">PLZ</label>
                                            <input className="uk-input" type="text" placeholder="PLZ" value={plz} onChange={handlePlzChange} />
                                        </div>


                                    </div>
                                </div>
                                <ConfirmationModal
                                    id={'contactDataChangeModal'}
                                    callback={savePersonalDataChange}
                                    text={'Änderungen der Kontaktdaten speichern'}
                                />
                                <div className="uk-margin">
                                    <a className=" uk-button uk-button-primary uk-button-large uk-width-1-1" style={{ background: "#425cbb" }} onClick={personalDataChangeModal} >Änderungen Speichern</a>
                                </div>

                            </div>


                            <div className="uk-card uk-card-default uk-margin uk-card-body uk-card-hover">
                                <div className="uk-margin">
                                    <h1>Passwort ändern</h1>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="uk-margin">
                                            <label for="">Aktuelles Passwort</label>
                                            <input className="uk-input" type="password" placeholder="Aktuelles Passwort" value={currentPassword} onChange={handleCurrentPasswordChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label for="">Neues Passwort</label>
                                            <input className="uk-input" type="password" placeholder="Neues Passwort" value={newPassword1} onChange={handleNewPassword1Change} />
                                        </div>

                                        <div className="uk-margin">
                                            <label for="">Neues Passwort wiederholen</label>
                                            <input className="uk-input" type="password" placeholder="Neues Passwort bestätigen" value={newPassword2} onChange={handleNewPassword2Change} />
                                        </div>
                                    </div>

                                </div>
                                <ConfirmationModal
                                    id={'changePasswordModal'}
                                    callback={savePasswordChange}
                                    text={'Änderungen des Passworts speichern'}
                                />

                                <div className="uk-margin">
                                    <a className="uk-button uk-button-primary uk-button-large uk-width-1-1" style={{ background: "#425cbb" }} type="button" onClick={passwordChangeModal} >Änderungen Speichern</a>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="col-md-2" />
            </div></div>)}

            <Footer />
        </div>

    )

}

PersonalData.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(PersonalData);