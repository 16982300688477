import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Logo from '../../images/vorsorger_logo.png';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2),
      textAlign: 'center'
    },
    title: {
      flexGrow: 1,
      textAlign: "center",

    },
    logo: {
      maxWidth: 50,
      marginRight: '10px'
    }
  })
);

const Header = (props) => {


  const classes = useStyles();

  const history = useHistory();

  const handleLogOut = () => {
    props.logoutUser(history);

  }

  const { user } = props.auth;

  return (

    <div className={classes.root}>
      <nav className="uk-navbar-container uk-margin" >
        <div className="uk-navbar-left">

          <a href='/' style={{ textDecoration: "none" }}>
            <div className="uk-navbar-item uk-logo" ><img alt="" src={Logo} className={classes.logo} /></div>
          </a>
          <div className="uk-navbar-item text-center" >
            <div className="text-center">DieVorsorger Vorsorge- und Vermögensberatung GmbH</div>
          </div>

          <div className="uk-navbar-right">
            <div className="uk-navbar-item uk-margin-small-right" >
              <SignOut>
                <div className=""><a href="#">{user.firstname + " " + user.lastname}</a></div>
                {/* <div className=""><a href="#">{token}</a></div> */}
                <DropdownElement>
                  <span onClick={handleLogOut}>Abmelden</span>
                </DropdownElement>
              </SignOut>

            </div>
          </div>

        </div>

      </nav>
    </div>
  )
}

const DropdownElement = styled.div`
    position: absolute;
    top: 48px;
    right: 0px;
    background: rgb(240, 240, 240);
    border: 1px solid rgba(151, 151, 151, 0.34);
    border-radius: 4px;
    box-shadow: rgba(0 0 0 / 50%) 0px 0px 18px;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 3px;
    width: 100px;
    opacity: 0;
`;

const SignOut = styled.div`
    position: relative;
    height: 48px;
    width: 48px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
   
    &:hover{
        ${DropdownElement}{
            opacity: 1;
            transition-duration: 1s;
        }
    }
`;

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Header);