import React from 'react';

const CustomerListElement = (props) => {
    return (
        <li>
            <a className="" href={"/customer-details/" + props.email}>{`${props.CustomerName} (${props.email})`} </a>
        </li>
    )
}

export default CustomerListElement;