import React from 'react';



const ConfirmationModal = (props) => {

    // Text

    // Callback if confirmed

    // close modal

    return(
        <div>
            <div id={props.id}  className="uk-modal">
                <div class="uk-modal-dialog uk-modal-body">
                    <p>{props.text}</p>
                    <button class="uk-button uk-button-default uk-modal-close " type="button">Abbrechen</button>
                    <button class="uk-button uk-button-primary uk-modal-close " style={{marginLeft: "10px", background: "#425cbb"}} type="button" onClick={props.callback}>Bestätigen</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal;