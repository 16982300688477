import React, { useEffect, useState } from 'react';
import Header from '../AdminDashboard/Header';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Footer from '../AdminDashboard/Footer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import { API_ENDPOINT, DATA_SRC_HUBSPOT_CALENDAR } from '../../utils/constants';
import loader from '../../images/spinner.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
      marginTop: '10px',
    },
    headingInPaper: {
      paddingTop: "20px",
    },
    paper: {
      padding: "10px",
      margin: "20px",
      textDecoration: 'none',
      "&:hover": {
        background: "#efefef",
      }
    },
    iconstyle: {
      color: "#4860b6"
    },
  })
);

const Calendar = (props) => {

  const classes = useStyles();

  const {
    buttonLabel,
    className
  } = props;

  const [eventData, SetEventData] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);
  const [startDate, SetStartDate] = useState("");
  const [endDate, SetEndDate] = useState("");
  const [meetingName, SetMeetingName] = useState("");

  useEffect(() => {
    if(isLoading){
      getNextEvent();
    }
  });

  const getNextEvent = async () => {

    const { user } = props.auth;
    const email = user.email;

    await axios.get(`${API_ENDPOINT}/api/hubspotevents/${email}`, { headers: {"auth-token" : props.auth.token} })
      .then((response) => {
        
        if(response.data.length === 0){
          SetEventData(response.data);

        }else{

          SetMeetingName(response.data.ownerName);
          SetStartDate(new Date(response.data.startTime).toLocaleString());
          SetEndDate(new Date(response.data.endTime).toLocaleTimeString());
          SetEventData(response.data);
        }
      });
    SetIsLoading(false);
  }

  return (
    <div>
      <Header />
      <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
        Meine Beratungstermine
      </h1>
      <div className="row" style={{ margin: "20px" }}>
        <div className="col-md-2" />
        <div className="col-md-4">

          <div class="meetings-iframe-container" data-src={DATA_SRC_HUBSPOT_CALENDAR}></div>
        </div>

        <div className="col-md-4">
          <div className="container">
            <div className="row text-center">

              <div className="col-md-12">
              
                <a style={{ textDecoration: "none" }}>
                  <div class="uk-card uk-card-default uk-card-body uk-card-hover">
                    <div><i class="fas fa-clock fa-5x" style={{ color: "#425cbb" }} ></i></div>
                    <h4 className={classes.headingInPaper}>Nächster Termin</h4>
                    {
                      isLoading ? (<img src={loader}/>) : (
                      
                      <div>
                        <p>{startDate} - {endDate}</p>
                        <p>{meetingName}</p></div>)
                    }
                  </div>
                </a>
              </div>

            </div>
          </div>
        </div>
        <div className="col-md-2" />
      </div>
      <Footer />
    </div>

  )

}

Calendar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Calendar);