import React from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import VideoElement from './VideoElement';
import UIkit from 'uikit';
import CategoryModal from '../../Modals/CategoryModal';
import CreateVideoModal from '../../Modals/CreateVideoModal';

const CategoryElement = (props) => {

  const modalTitleAddVideo = "#" + `modal-add-video-${props.category}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g, "");
  const modalTitleAddVideoId = `modal-add-video-${props.category}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g, "");

  const modalTitleEditCategory = "#" + `createEditCategoryModal-${props.category}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g, "");
  const modalTitleEditCategoryId = `createEditCategoryModal-${props.category}`.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').replace(/\s+/g, "");


  const deleteCategroy = () => {
    console.log("delete");
    axios.delete(`${API_ENDPOINT}/api/videos/category`, { headers: { "auth-token": props.token }, data: { category: props.category } });
  }

  const openNewVideoModal = () => {
    UIkit.modal(modalTitleAddVideo).show();
  }

  const createNewVideo = (newVideoName, newVideoIframe) => {
    UIkit.modal(modalTitleAddVideo).hide();

    axios({
      url: `${API_ENDPOINT}/api/videos/video`,
      method: 'post',
      headers: { 'auth-token': props.token },
      data: { videoName: newVideoName, videoIframe: newVideoIframe, category: props.category }
    }).then((response) => {
      if (response.status === 200) {
        // All went right
        UIkit.notification({
          message: 'Kategorie erfolgreich erstellt',
          status: 'success',
          pos: 'top-right',
          timeout: 5000
        });
      }
    }).catch((error) => {
      console.log(error);

      UIkit.notification({
        message: 'Fehler beim erstellen der Kategorie',
        status: 'warning',
        pos: 'top-right',
        timeout: 5000
      });
    });
  }

  const openEditCategoryModal = () => {
    UIkit.modal(modalTitleEditCategory).show();
  }

  const editCategory = (newCategoryName) => {

    axios({
      url: `${API_ENDPOINT}/api/videos/category`,
      method: 'put',
      headers: { 'auth-token': props.token },
      data: { oldCategory: props.category, newCategory: newCategoryName }
    }).then((response) => {
      if (response.status === 200) {
        // All went right
        UIkit.notification({
          message: 'Kategorie erfolgreich geändert',
          status: 'success',
          pos: 'top-right',
          timeout: 5000
        });
      }
    }).catch((error) => {
      console.log(error);

      UIkit.notification({
        message: 'Fehler beim ändern der Kategorie',
        status: 'warning',
        pos: 'top-right',
        timeout: 5000
      });
    });
  }

  return (

    <li>
      <CreateVideoModal
        id={modalTitleAddVideoId}
        text={"Neues Video Erstellen"}
        callback={(videName, videoIframe) => createNewVideo(videName, videoIframe)}
        videoName={""}
        videoIframe={""}
      />

      <CategoryModal
        id={modalTitleEditCategoryId}
        text={"Kategorie Bearbeiten"}
        callback={(category) => editCategory(category)}
        category={props.category}
      />

      <a className="uk-accordion-title" href="#">

        {props.category}




      </a>
      <div className="uk-accordion-content">
        <div className="uk-margin">
          <ul className="uk-list uk-list-divider">
            {props.videos.map((value, index) => {
              return <VideoElement key={index} videoName={value.videoName} category={props.category} videoIframe={value.videoIframe} token={props.token} />
            })}
          </ul>
        </div>

        <div className="uk-align-right" >
          <span uk-tooltip="title: Video hinzufügen" uk-icon="icon: plus-circle" onClick={openNewVideoModal} style={{ cursor: "pointer" }}></span>

          <span uk-tooltip="title: Kateogire bearbeiten" uk-icon="icon: pencil" onClick={openEditCategoryModal} style={{ cursor: "pointer" }}></span>

          <span uk-tooltip="title: Kateogire löschen" uk-icon="icon: trash" onClick={deleteCategroy} style={{ cursor: "pointer" }}></span>


        </div>
      </div>
    </li>
  );
}

export default CategoryElement;