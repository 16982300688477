import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import axios from 'axios';
import { API_ENDPOINT } from '../../../utils/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CategoryElement from './CategoryElement';
import UIkit from 'uikit';
import CategoryModal from '../../Modals/CategoryModal';

const VideoDashboard = (props) => {
  const [videos, SetVideos] = useState([]);


  useEffect(() => {
    getVideos();
  });

  const getVideos = () => {
    axios.get(`${API_ENDPOINT}/api/videos`, { headers: { "auth-token": props.auth.token } })
      .then((response) => {
        SetVideos(response.data);
      });
  }

  const openCreateCategoryModal = () => {
    UIkit.modal('#createCategoryModal').show();
  }

  const createCategory = (category) => {

    axios({
      url: `${API_ENDPOINT}/api/videos/category`,
      method: 'post',
      headers: { 'auth-token': props.auth.token },
      data: { category: category }
    }).then((response) => {

      if (response.status === 200) {
        // All went right
        UIkit.notification({
          message: 'Kategorie erfolgreich erstellt',
          status: 'success',
          pos: 'top-right',
          timeout: 5000
        });
      }
    }).catch((error) => {

      UIkit.notification({
        message: 'Fehler beim erstellen der Kategorie',
        status: 'warning',
        pos: 'top-right',
        timeout: 5000
      });

    });
  }



  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        <Header />
        <h1 style={{ flexGrow: 1, textAlign: "center", margin: "20px" }}>
          Videos
        </h1>
        <CategoryModal
          id={"createCategoryModal"}
          text={"Kategorie erstellen"}
          callback={(category) => createCategory(category)}
        />
        <div className="row" style={{ margin: "20px" }}>
          <div className="col-md-2" />
          <div className="col-md-8">

            <ul uk-accordion="multiple: false" className="uk-card uk-card-default uk-card-body uk-card-hover" style={{ padding: "30px" }}>
              {videos.map((value, index) => {
                return <CategoryElement key={index} category={value.category} videos={value.videos} token={props.auth.token} />
              })}
            </ul>


          </div>
          <div className="col-md-2">

          </div>
        </div>

        <div className="row" style={{ margin: "20px" }} >
          <div className="uk-align-center">
            <button className="uk-button" style={{ backgroundColor: "#425cbb", color: "white" }} onClick={openCreateCategoryModal}>Neue Kategorie erstellen </button>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )

}

VideoDashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(VideoDashboard);