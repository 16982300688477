import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../images/vorsorger_logo.png';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../redux/actions/authActions';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    maxWidth: 100,
    marginRight: '10px'
  }
}));

const SignUp = (props) => {

  const [firstName, SetFirstName] = React.useState("");
  const [lastName, SetLastName] = React.useState("");
  const [email, SetEmail] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [acceptDataProctection, SetAcceptDataProctection] = React.useState(false);

  const handleFirstNameChange = (event) => {
    SetFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    SetLastName(event.target.value);
  }

  const handleEmailChange = (event) => {
    SetEmail(event.target.value);
  }

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  }

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  }

  const handleAcceptDataProctectionChange = (event) => {
    SetAcceptDataProctection(event.target.checked);
    console.log(event.target.checked);
  }

  const createAccount = () => {
    const newUser = {
      email: email, 
      password1: password1, 
      password2: password2,
      firstname: firstName, 
      lastname: lastName, 
      acceptDataProctection: acceptDataProctection
    };
    props.registerUser(newUser, props.history);
  }

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      props.history.push('/dashboard');
    }
  });
  

  return (
  <div className="uk-section  uk-flex uk-flex-middle uk-animation-fade" uk-height-viewport>
	<div className="uk-width-1-1">
		<div className="uk-container">
			<div className="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
				<div className="uk-width-1-1@m">
					<div className="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
          <img src={Logo} width={100} className="uk-align-center"/>
						<h3 className="uk-card-title uk-text-center">Account erstellen</h3>
						<form>
              <div className="uk-margin">
								<div className="uk-inline uk-width-1-1">
									<span className="uk-form-icon" uk-icon="icon: user"></span>
									<input className="uk-input uk-form-large" value={firstName} onChange={handleFirstNameChange} type="text" placeholder="Vorname"/>
								</div>
							</div>
              <div className="uk-margin">
								<div className="uk-inline uk-width-1-1">
									<span className="uk-form-icon" uk-icon="icon: users"></span>
									<input className="uk-input uk-form-large" value={lastName} onChange={handleLastNameChange} type="text" placeholder="Nachname"/>
								</div>
							</div>
							<div className="uk-margin">
              <div class="uk-text uk-margin">
                Bitte beachte dass eine Registrierung nur mit der Email-Adresse möglich ist, 
                mit der du auch als Kunde registriert bist
              </div>
								<div className="uk-inline uk-width-1-1">
									<span className="uk-form-icon" uk-icon="icon: mail"></span>
									<input className="uk-input uk-form-large" value={email}  onChange={handleEmailChange} type="text" placeholder="E-Mail Adresse"/>
								</div>
							</div>
							<div className="uk-margin">
								<div className="uk-inline uk-width-1-1">
									<span className="uk-form-icon" uk-icon="icon: lock"></span>
									<input className="uk-input uk-form-large" value={password1} onChange={handlePassword1Change} type="password" placeholder="Passwort"/>	
								</div>
							</div>
              <div className="uk-margin">
								<div className="uk-inline uk-width-1-1">
									<span className="uk-form-icon" uk-icon="icon: lock"></span>
									<input className="uk-input uk-form-large" value={password2} onChange={handlePassword2Change} type="password" placeholder="Passwort wiederhohlen"/>	
								</div>
							</div>
              
              <div className="uk-margin">
								<div className="uk-inline uk-width-1-1">
									<label><input class="uk-checkbox" type="checkbox" value={acceptDataProctection} onChange={handleAcceptDataProctectionChange}/> Ich habe die Hinweise zum <a target="_blank" href="https://www.dievorsorger.at/datenschutzerklaerung/">Datenschutz</a> gelesen und akzeptiere sie.*</label>
								</div>
							</div>
							<div className="uk-margin">
								<a className="uk-button uk-button-primary uk-button-large uk-width-1-1" style={{background: "#425cbb"}} onClick={createAccount}>Account Erstellen</a>
							</div>
							<div className="uk-text-small uk-text-center">
								Bereits registriert? <a href="/login" style={{color: "#425cbb"}}>Login</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
  
</div>
  );
}

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { registerUser })(withRouter(SignUp));