import React from 'react';


const VideoDisplayModal = (props) => {

    return(
        <div id={props.id} class="uk-flex-top" className='uk-modal'>
            <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                <div dangerouslySetInnerHTML={{__html: props.videoIframe}} style={{display: "flex",  alignItems: "center", justifyContent: "center"}}></div>
            </div>
        </div>
    )
}

export default VideoDisplayModal;