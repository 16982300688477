import React, { useState } from 'react';



const CategoryModal = (props) => {

    const [category, setCategory] = useState(props.category);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    }

    return (
        <div>
            <div id={props.id} className="uk-modal">
                <div class="uk-modal-dialog uk-modal-body">
                    <p>{props.text}</p>
                    <input type="text" name="videoName" className="uk-input" placeholder="Kategorie-Name" value={category} onChange={handleCategoryChange} />
                    <button style={{ marginTop: "10px" }} class="uk-button uk-button-default uk-modal-close " type="button">Abbrechen</button>
                    <button style={{ marginTop: "10px", marginLeft: "10px", background: "#425cbb" }} class="uk-button uk-button-primary uk-modal-close " type="button" onClick={() => props.callback(category)}>Bestätigen</button>
                </div>
            </div>
        </div>
    )
}

export default CategoryModal;